<template>
  <div>
    <div>
      <b-card>
        <modal-actions
          class="mb-3"
          @close="$router.push({ name: 'committee-inscription' })"
        />

        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <p class="h2 text-secondary">Modifier inscription</p>
          <b-button variant="secondary">Confirmer</b-button>
          <hr class="w-100" />
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group label-cols="4" label-cols-lg="3" label="Rôle">
              <multiselect
                :options="['Rôle 1', 'Rôle 2', 'Rôle 3']"
                placeholder="Choisir un rôle"
              ></multiselect>
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="3" label="Utilisateur">
              <multiselect
                :options="['John Doe']"
                placeholder="Choisir un utilisateur"
              ></multiselect>
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="3" label="Date">
              <b-datepicker></b-datepicker>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group label-cols="4" label-cols-lg="3" label="Session">
              <multiselect
                :options="['Session 1', 'Session 2', 'Session 3']"
                placeholder="Choisir une session"
              ></multiselect>
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="3" label="Dossier">
              <multiselect
                multiple
                :options="['Dossier 1', 'Dossier 2', 'Dossier 3']"
                placeholder="Choisir un dossier"
              ></multiselect>
            </b-form-group>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import countries from "@/assets/countries/data.json";
import ModalActions from "@/components/custom/ModalActions.vue";

import { mapGetters } from "vuex";
import {USER_URL, DOSSIER_URL,SESSION_URL} from '@/helpers/constURL'

export default {
  components: { ModalActions },
  data: () => ({
    countries: countries,
    selectedCountries: [],
  }),
  methods: {
    editItem: function () {},
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("role/fetchAllContributorRoles");
    this.$store.dispatch("mange/fetchAllSessions");
    this.$store.dispatch("dossier/fetchAllDossier");
  },
  computed: {
    ...mapGetters("role", ["CONTRIBUTOR_ROLES"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("manage", ["SESSIONS"]),
    ...mapGetters("dossier", ["DOSSIER"]),

    responsables() {
      //return array of responsables

      var data = this.RESPONSABLES.map((responsable) => ({
        id: `/api/${USER_URL}/${responsable.id}`,
        full_name: responsable.firstname + " " + responsable.lastname,
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      }));

      return data;
    },
    role() {
      //return array of responsables

      var data = this.CONTRIBUTOR_ROLES;

      return data;
    },
    session() {
      var data = this.SESSIONS.map((session) => ({
        id: `/api/${SESSION_URL}/${session.id}`,
        full_name: session.name,
        value: session.id,
        text: session.name,
      }));

      return data;
    },
    dossier() {
      var data = this.DOSSIER.map((dossier) => ({
        id: `/api/${DOSSIER_URL}/${dossier.id}`,
        full_name: dossier.name,
        value: dossier.id,
        text: dossier.name,
      }));

      return data;
    },
  },
};
</script>

<style></style>
